import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { useStaticQuery, graphql, Link } from 'gatsby';

import IconFloatingApplication from '../IconFloating/Applications';
import BgImageDark from '../BgImagedark';

import sample from 'common/src/assets/image/hosting/author-3.jpg';

import BgImageLight from '../BgImageLight';

import './infoplatform.css';

const InfoPlatformSection = ({
  name,
  sectionWrapper,
  row,
  col,
  title,
  description,
  button,
  textArea,
  contentArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  secTitleWrapper,
  secHeading,
  secText,
  box_size,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          INFO_PLATFORM {
            RAPFlow {
              title
              description
              class
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            RAPBot {
              title
              description
              class
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            GettingStarted {
              title
              description
              class
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Box {...sectionWrapper}>
      {Data.hostingJson.INFO_PLATFORM[name].map((item, index) => (
        <Box key={`plaform-${index}`} className={`${item.class}`}>
          <Box className="overview_section">
            <BgImageLight />
            <Container>
              <Box {...row} className="infoplatform_container">
                <Heading {...title} content={item.title} />
                <Box {...col} className="info_col1">
                  <Fade left cascade>
                    <Box className="InfoBanner_Image_container">
                      <Image
                        src={item.avatar.childImageSharp.fluid.src}
                        alt={`${item.title} of RAP`}
                        className="icon_image"
                      />
                    </Box>
                  </Fade>
                </Box>
                <Box {...col} className="info_col2">
                  <Fade bottom cascade>
                    <Text
                      {...description}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Fade>
                  <div className="empty_space10" />
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

InfoPlatformSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  box_size: PropTypes.object,
};

InfoPlatformSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_platform',
    pb: '25px',
    backgroundColor: '#f1f1f1',
    // pb: '25px',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#eb4d4b',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  textArea: {
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    ml: '-200px',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '25px',
    textAlign: 'center',
    display: 'block',
    width: '100%',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: '25px',
    mb: '25px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
};

export default InfoPlatformSection;
