import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import Ctasection from '../../containers/Hosting/Ctasection';
import BannerSectionPlatform from '../../containers/Hosting/Banner/BannerPlatform';
import InfoPlatformSection from '../../containers/Hosting/InfoPlatform';
import SequenceStepSection from '../../containers/Hosting/SequenceSteps';
import TopRibbon from '../../containers/Hosting/TopRibbon';

const GettingStartedPage = () => {
  const name = 'GettingStarted';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO title="Getting Started | Platforms" />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="GettingStartedPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionPlatform name={name} />
          <InfoPlatformSection name={name} />
          <SequenceStepSection />
          <Ctasection name={name} />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default GettingStartedPage;
