import styled from 'styled-components';

const SequenceStepsWrapper = styled.div`
  .sequenceMobile {
    display: none;
  }
  .sequenceDesktop {
    margin-top: -50px;
    margin-bottom: 60px;
  }
  .empty_space {
    width: 100%;
    height: 0;
    margin-top: 170px;
  }
  .empty_space2 {
    width: 100%;
    height: 0;
    margin-top: -80px;
  }
  .empty_space3 {
    width: 100%;
    height: 0;
    margin-top: 100px;
  }
  .empty_space4 {
    width: 100%;
    height: 0;
    margin-top: 15px;
  }
  .sequence_img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    object-fit: contain;
    position: relative;
  }
  .sequence_img_left,
  .sequence_img_right {
    position: relative;
  }

  .arrow_img {
    width: 50%;
    max-width: 150px;
    display: inline-block;
    position: absolute;
  }
  .arrow_img_right {
    left: 50%;
  }
  .arrow_img_left {
    right: 50%;
    transform: scaleX(-1);
  }

  @media only screen and (max-width: 991px) {
    .sequenceDesktop {
      margin-top: -40px;
    }
    .empty_space {
      margin-top: 125px;
    }
    .empty_space2 {
      margin-top: -85px;
    }
    .empty_space3 {
      margin-top: 55px;
    }
    .empty_space4 {
      margin-top: -25px;
    }
  }
  @media only screen and (max-width: 768px) {
    .sequenceDesktop {
      display: none;
    }
    .sequenceMobile {
      display: block;
    }
    .sequence_data {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 10px 0;
      margin-bottom: 0;
      // background-color: red;
    }
    .sequence_data h6,
    .sequence_data h5,
    .sequence_data h4,
    .sequence_data h3,
    .sequence_data h2,
    .sequence_data h1 {
      margin-bottom: 10px;
    }
    .sequence_data span {
      margin-bottom: 20px;
    }
    .sequence_data img {
      height: 100%;
      width: 100%;
      max-width: 90px;
      max-height: 90px;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }
`;

export default SequenceStepsWrapper;
