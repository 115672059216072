import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import SequenceStepsWrapper from './sequencesteps.style';

import ArrowRight from 'common/src/assets/image/hosting/SequenceSteps/arrow-left.png';
import ArrowLeft from 'common/src/assets/image/hosting/SequenceSteps/arrow-right.png';
import APIGeneration from 'common/src/assets/image/hosting/SequenceSteps/API-Generation.png';
import Deployment from 'common/src/assets/image/hosting/SequenceSteps/Deployment.png';
import IdentifyFlow from 'common/src/assets/image/hosting/SequenceSteps/Identify-Flow-Pipeline.png';
import PlatformPicks from 'common/src/assets/image/hosting/SequenceSteps/Platform-Picks-the-Best-fit-Algorithm.png';
import PlatformTrains from 'common/src/assets/image/hosting/SequenceSteps/Platform-Trains-Itself.png';
import TestSolution from 'common/src/assets/image/hosting/SequenceSteps/Test-the-Solution.png';
import UploadTraining from 'common/src/assets/image/hosting/SequenceSteps/Upload-Training-Data.png';

import sample from 'common/src/assets/image/hosting/badge.png';

const SequenceStepSection = ({
  name,
  sectionWrapper,
  row,
  row2,
  col,
  title,
  description,
  button,
  textArea,
  contentArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  secTitleWrapper,
  secHeading,
  secText,
  box_size,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <SequenceStepsWrapper>
          <Box {...textArea}>
            <Heading {...title} content="Sequence of Steps" />
            <Text
              {...description}
              content="The initial step requires identifying all the operations and tasks that can be automated using either or both of the RAP AI engines. This might be a set of text or image recognition tasks using EyeMagic or NLP processing, entity extraction and intent classification using ResponseGenie."
            />
            <Text
              {...description}
              content="Determine whether classification is required – does the operation or operations deal with multiple types of documents, images or other formats. This raises the degree of complexity of operations."
            />
          </Box>
          <Box className="sequenceDesktop">
            <Box {...row}>
              <Box {...col} className="sequence_data">
                <Heading
                  {...secHeading}
                  content="1. Identify Flow & Pipeline"
                />
                <Text
                  {...secText}
                  content="Determine all the workflows and pipeline for tasks and processes to be automated."
                />
              </Box>
              <Box {...col} className="sequence_img_left">
                <Image src={IdentifyFlow} alt="alt" className="sequence_img" />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_right"
                />
              </Box>
              <Box {...col} className="sequence_img_right">
                <div className="empty_space" />
                <Image
                  src={UploadTraining}
                  alt="alt"
                  className="sequence_img"
                />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_left"
                />
              </Box>
              <Box {...col} className="sequence_data">
                <div className="empty_space" />
                <Heading {...secHeading} content="2. Upload Training Data" />
                <Text
                  {...secText}
                  content="Upload your data to perform training. In some cases, annotation may be required."
                />
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col} className="sequence_data">
                <div className="empty_space2" />
                <Heading {...secHeading} content="3. Platform Trains Itself" />
                <Text
                  {...secText}
                  content="The RAP AI Platform runs multiple algorithms over the data sets to compare efficiency."
                />
              </Box>
              <Box {...col} className="sequence_img_left">
                <div className="empty_space2" />
                <Image
                  src={PlatformTrains}
                  alt="alt"
                  className="sequence_img"
                />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_right"
                />
              </Box>
              <Box {...col} className="sequence_img_right">
                <div className="empty_space3" />
                <Image src={PlatformPicks} alt="alt" className="sequence_img" />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_left"
                />
              </Box>
              <Box {...col} className="sequence_data">
                <div className="empty_space3" />
                <Heading
                  {...secHeading}
                  content="4. Platform Picks the Best-fit Algorithm"
                />
                <Text
                  {...secText}
                  content="The RAP AI platform freezes the best-fit algorithm for the use case at hand."
                />
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col} className="sequence_data">
                <div className="empty_space2" />
                <Heading {...secHeading} content="5. Test the Solution" />
                <Text
                  {...secText}
                  content="Test the efficiency and accuracy of the automated pipeline. Your feedback will then be incorporated."
                />
              </Box>
              <Box {...col} className="sequence_img_left">
                <div className="empty_space2" />
                <Image src={TestSolution} alt="alt" className="sequence_img" />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_right"
                />
              </Box>
              <Box {...col} className="sequence_img_right">
                <div className="empty_space3" />
                <Image src={APIGeneration} alt="alt" className="sequence_img" />
                <Image
                  src={ArrowRight}
                  alt="alt"
                  className="arrow_img arrow_img_left"
                />
              </Box>
              <Box {...col} className="sequence_data">
                <div className="empty_space3" />
                <Heading {...secHeading} content="6. API Generation" />
                <Text
                  {...secText}
                  content="The RAP AI platform generates an API to plug n play with your existing systems."
                />
              </Box>
            </Box>
            <Box {...row2}>
              <Box {...col} className="sequence_data">
                <div className="empty_space4" />
                <Heading {...secHeading} content="7. Deployment" />
                <Text
                  {...secText}
                  content="Deploy your application and run the show yourself with our initial guidance."
                />
              </Box>
              <Box {...col} className="sequence_img_left">
                <div className="empty_space4" />
                <Image src={Deployment} alt="alt" className="sequence_img" />
              </Box>
            </Box>
          </Box>
          <Box className="sequenceMobile">
            <Box {...row}>
              <Box {...col} className="sequence_data">
                <Image src={IdentifyFlow} alt="alt" className="sequence_img" />
                <Box className="sequence_data">
                  <Heading
                    {...secHeading}
                    content="1. Identify Flow & Pipeline"
                  />
                  <Text
                    {...secText}
                    content="Determine all the workflows and pipeline for tasks and processes to be automated."
                  />
                </Box>
              </Box>
              <Box {...col} className="sequence_data">
                <Image
                  src={UploadTraining}
                  alt="alt"
                  className="sequence_img"
                />
                <Heading {...secHeading} content="2. Upload Training Data" />
                <Text
                  {...secText}
                  content="Upload your data to perform training. In some cases, annotation may be required."
                />
              </Box>
              <Box {...col} className="sequence_data">
                <Image
                  src={PlatformTrains}
                  alt="alt"
                  className="sequence_img"
                />
                <Heading {...secHeading} content="3. Platform Trains Itself" />
                <Text
                  {...secText}
                  content="The RAP AI Platform runs multiple algorithms over the data sets to compare efficiency."
                />
              </Box>
              <Box {...col} className="sequence_data">
                <Image src={PlatformPicks} alt="alt" className="sequence_img" />
                <Heading
                  {...secHeading}
                  content="4. Platform Picks the Best-fit Algorithm"
                />
                <Text
                  {...secText}
                  content="The RAP AI platform freezes the best-fit algorithm for the use case at hand."
                />
              </Box>
              <Box {...col} className="sequence_data">
                <Image src={TestSolution} alt="alt" className="sequence_img" />
                <Heading {...secHeading} content="5. Test the Solution" />
                <Text
                  {...secText}
                  content="Test the efficiency and accuracy of the automated pipeline. Your feedback will then be incorporated."
                />
              </Box>
              <Box {...col} className="sequence_data">
                <Image src={APIGeneration} alt="alt" className="sequence_img" />
                <Heading {...secHeading} content="6. API Generation" />
                <Text
                  {...secText}
                  content="The RAP AI platform generates an API to plug n play with your existing systems."
                />
              </Box>
              <Box {...col} className="sequence_data">
                <Image src={Deployment} alt="alt" className="sequence_img" />
                <Heading {...secHeading} content="7. Deployment" />
                <Text
                  {...secText}
                  content="Deploy your application and run the show yourself with our initial guidance."
                />
              </Box>
            </Box>
          </Box>
        </SequenceStepsWrapper>
      </Container>
    </Box>
  );
};

SequenceStepSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  row2: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  box_size: PropTypes.object,
};

SequenceStepSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'sequence_step',
    pb: '0',
    // pb: '25px',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#252525',
    mt: '0',
    mb: '0',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['17px', '16px'],
    fontWeight: 'bold',
    // color: '#0f2137',
    color: '#FC5546',
    letterSpacing: '-0.025em',
    mt: '0',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
    className: 'row',
  },
  row2: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ml: '-15px',
    mr: '-15px',
    className: 'row',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '46%', '25%', '25%', '25%'],
    className: 'col',
  },
  textArea: {
    // width: ['100%', '90%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    ml: '-200px',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '25px',
    textAlign: 'center',
    display: 'block',
    width: '100%',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: '25px',
    mb: '25px',
    textAlign: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
};

export default SequenceStepSection;
